import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { useDispatch } from "react-redux";

import { containerStyles, closeIconStyles, titleStyles } from "./styles/Header.styles";
import { BrandIcon } from "./BrandIcon";
import { changeExpandedStatus } from "../store/actions/genericActions";

export const Header = ({ customTitle }: { customTitle?: string }) => {
    const dispatch = useDispatch();

    return (
        <Box element="HEADER" as="header" {...containerStyles}>
            <BrandIcon />
            <Text element="HEADER_TITLE" as="h2" {...titleStyles}>
                {customTitle || "Live Chat"}
            </Text>
            <Text
                element="HEADER_CLOSE_ICON"
                as="p"
                {...closeIconStyles}
                onClick={() => dispatch(changeExpandedStatus({ expanded: false }))}
            >
                <CloseIcon decorative={false} title="hover chat window" />
            </Text>
        </Box>
    );
};
