import { PasteCustomCSS } from "@twilio-paste/core/customization";
import merge from "lodash.merge";

const defaultElements: Record<string, PasteCustomCSS> = {
    SELECTED_MESSAGES_CONTAINER: {
        alignItems: "center",
        display: "inline-flex",
        outline: "none"
    },
    SELECTED_MESSAGE_BUTTON: {
        margin: "space20",
        marginRight: "space0",
        borderRadius: "borderRadius20",
        boxShadow: "none",
        letterSpacing: "initial",
        fontSize: "inherit",
        fontWeight: "fontWeightSemibold",
        textTransform: "none",
        variants: {
            secondary: {
                backgroundColor: "colorBackground",
                ":hover": {
                    backgroundColor: "colorBackgroundStronger",
                    color: "colorTextUser",
                    boxShadow: "none"
                },
                ":disabled": {
                    ":hover": {
                        backgroundColor: "colorBackgroundPrimaryWeakest",
                        color: "colorTextWeaker"
                    }
                }
            },
            primary: {
                backgroundColor: "colorBackgroundPrimaryStrong",
                ":disabled": {
                    backgroundColor: "colorBackgroundPrimaryStrongest",
                    color: "colorTextWeakest"
                }
            }
        }
    },
    CUSTOM_SEND_BUTTON: {
        margin: "space20",
        width: "32px",
        height: "32px",
        borderRadius: "borderRadius20",
        backgroundColor: "colorBackgroundPrimary",
        boxShadow: "none",
        variants: {
            primary: {
                ":hover": {
                    backgroundColor: "colorBackgroundPrimaryStronger",
                    boxShadow: "none"
                },
                ":disabled": {
                    backgroundColor: "colorBackgroundPrimaryStrong",
                    ":hover": {
                        backgroundColor: "colorBackgroundPrimaryStrong"
                    }
                }
            }
        }
    },
    MESSAGE_INPUT_BOX: {
        display: "inline-block",
        boxShadow: "none",
        ":focus-within": {
            boxShadow: "none"
        },
        ":hover": {
            boxShadow: "none"
        }
    },
    MESSAGE_INPUT: {
        boxShadow: "none",
        ":hover": {
            boxShadow: "none"
        },
        ":focus": {
            boxShadow: "none"
        },
        ":focus-within": {
            boxShadow: "none"
        }
    },
    MESSAGE_INPUT_ELEMENT: {
        fontSize: "fontSize40",
        lineHeight: "lineHeight40",
        "::placeholder, ::-webkit-input-placeholder, :focus::placeholder": {
            fontStyle: "normal"
        }
    },
    MESSAGE_INPUT_FORM: {
        borderRadius: "borderRadius30",
        ":hover": {
            boxShadow: "shadowBorderPrimaryStrongest"
        },
        ":focus": {
            boxShadow: "shadowBorderPrimary"
        },
        ":focus-within": {
            boxShadow: "shadowBorderPrimary"
        }
    },
    MESSAGE_INPUT_FORM_ELEMENT: {
        fontSize: "fontSize40",
        lineHeight: "lineHeight40",
        minHeight: "3rem",
        "::placeholder, ::-webkit-input-placeholder, :focus::placeholder": {
            fontStyle: "normal"
        }
    },
    MANAGE_BUTTONS_PRIMARY: {
        boxShadow: "none",
        ":hover": {
            backgroundColor: "colorBackgroundPrimaryStronger",
            color: "colorTextIconBrandInverse",
            boxShadow: "none"
        },
        ":disabled": {
            backgroundColor: "colorBackgroundPrimaryWeakest",
            color: "colorTextWeaker",
            ":hover": {
                backgroundColor: "colorBackgroundPrimaryWeakest",
                color: "colorTextWeaker"
            }
        },
        letterSpacing: "initial",
        fontSize: "fontSize40",
        textTransform: "none",
        borderRadius: "borderRadius30",
        minHeight: "3rem"
    },
    MANAGE_BUTTONS_SECONDARY: {
        boxShadow: "shadowBorderPrimary",
        ":hover": {
            backgroundColor: "colorBackgroundPrimaryWeakest",
            color: "colorTextUser",
            boxShadow: "shadowBorderPrimary"
        },
        letterSpacing: "initial",
        fontSize: "fontSize40",
        textTransform: "none",
        borderRadius: "borderRadius30",
        minHeight: "3rem"
    },
    TITLE_TEXT: {
        textTransform: "none",
        letterSpacing: "initial"
    },
    ALERT: {
        paddingTop: "space30",
        paddingBottom: "space30"
    },
    BRAND_ICON: {
        width: "32px",
        height: "20px"
    }
};

const brandElements: Record<string, Record<string, PasteCustomCSS>> = {
    bmw: {
        TITLE_TEXT: {
            textTransform: "uppercase",
            fontWeight: "fontWeightNormal"
        },
        SELECTED_MESSAGE_BUTTON: {
            fontWeight: "fontWeightBold"
        }
    },
    mercedes: {
        HEADER: {
            backgroundColor: "colorBackgroundBrand"
        },
        HEADER_TITLE: {
            color: "colorTextInverse"
        },
        HEADER_CLOSE_ICON: {
            color: "colorTextInverse"
        },
        MANAGE_BUTTONS_PRIMARY: {
            fontSize: "fontSize40",
            borderRadius: "borderRadius10"
        },
        MANAGE_BUTTONS_SECONDARY: {
            fontSize: "fontSize40",
            borderRadius: "borderRadius10"
        },
        TITLE_TEXT: {
            fontSize: "fontSize80",
            fontWeight: "fontWeightNormal",
            lineHeight: "lineHeight70"
        },
        SELECTED_MESSAGE_BUTTON: {
            fontSize: "fontSize30"
        },
        MESSAGE_INPUT_FORM: {
            borderRadius: "borderRadius0",
            ":hover": {
                boxShadow: "shadowBorderPrimaryStrongest"
            },
            ":focus": {
                boxShadow: "shadowBorderPrimary"
            },
            ":focus-within": {
                boxShadow: "shadowBorderPrimary"
            }
        }
    },
    mini: {
        TITLE_TEXT: {
            textTransform: "uppercase"
        },
        SELECTED_MESSAGE_BUTTON: {
            textTransform: "uppercase",
            fontWeight: "fontWeightNormal"
        },
        MANAGE_BUTTONS_PRIMARY: {
            fontSize: "fontSize30",
            textTransform: "uppercase",
            fontWeight: "fontWeightNormal"
        },
        MANAGE_BUTTONS_SECONDARY: {
            fontSize: "fontSize30",
            textTransform: "uppercase",
            fontWeight: "fontWeightNormal"
        }
    },
    jaguar: {
        MANAGE_BUTTONS_PRIMARY: {
            letterSpacing: "1.44px",
            fontSize: "fontSize20",
            textTransform: "uppercase"
        },
        MANAGE_BUTTONS_SECONDARY: {
            letterSpacing: "1.44px",
            fontSize: "fontSize20",
            textTransform: "uppercase"
        },
        TITLE_TEXT: {
            textTransform: "uppercase",
            fontSize: "fontSize50",
            letterSpacing: "3px"
        },
        SELECTED_MESSAGE_BUTTON: {
            letterSpacing: "1.44px",
            fontSize: "fontSize20",
            textTransform: "uppercase"
        }
    },
    landrover: {
        MANAGE_BUTTONS_PRIMARY: {
            letterSpacing: "3px",
            fontSize: "fontSize10",
            textTransform: "uppercase"
        },
        MANAGE_BUTTONS_SECONDARY: {
            letterSpacing: "3px",
            fontSize: "fontSize10",
            textTransform: "uppercase"
        },
        TITLE_TEXT: {
            textTransform: "uppercase",
            letterSpacing: "0.8px"
        },
        SELECTED_MESSAGE_BUTTON: {
            letterSpacing: "3px",
            fontSize: "fontSize10",
            textTransform: "uppercase"
        }
    },
    volvo: {
        MANAGE_BUTTONS_PRIMARY: {
            fontSize: "fontSize30",
            textTransform: "uppercase"
        },
        MANAGE_BUTTONS_SECONDARY: {
            fontSize: "fontSize30",
            textTransform: "uppercase"
        },
        SELECTED_MESSAGE_BUTTON: {
            fontSize: "fontSize20",
            textTransform: "uppercase"
        }
    }
};

export const createElements = (brandName: string) => merge({}, defaultElements, brandElements[brandName] ?? {});
