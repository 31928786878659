import { BoxStyleProps } from "@twilio-paste/core/box";

export const getOuterContainerStyles = (expanded: boolean): BoxStyleProps => ({
    position: "fixed",
    bottom: [`${expanded ? "space0" : "space50"}`, "space50"],
    right: [`${expanded ? "space0" : "space60"}`, "space60"],
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    zIndex: "zIndex90",
    height: [`${expanded ? "100%" : "auto"}`, "auto"]
});

export const innerContainerStyles: BoxStyleProps = {
    boxShadow: "shadowCard",
    display: "flex",
    flexDirection: "column",
    width: ["100%", "360px"],
    height: ["100%", "590px"],
    marginBottom: ["space0", "space50"],
    borderRadius: "borderRadius30",
    backgroundColor: "colorBackgroundBody"
};
